<template>
  <NuxtPage />
  <UNotifications />
</template>

<script setup>
  const { data: currentSite } = await useApi("/api/sites")
  provide("currentSite", currentSite)

  const {
    inAgeRestrictedLocation,
    country: geoCountry,
    location: geoLocation,
  } = useGeoLocation()

  provide("inAgeRestrictedLocation", inAgeRestrictedLocation)
  provide("geoCountry", geoCountry)
  provide("geoLocation", geoLocation)

  useHead({
    link: [
      {
        rel: "icon",
        type: "image/x-icon",
        href: `/images/sites/${currentSite.value.slug}/${currentSite.value.slug}-favicon.ico`,
      },
    ],
  })

  // TODO: Where should this really go?
  String.prototype.capitalize = function () {
    if (!this) return ""
    return this.charAt(0).toUpperCase() + this.slice(1)
  }
</script>
