export const useApi = (url, options = {}) => {
  const { $api } = useNuxtApp()
  const requestHeaders = useRequestHeaders(["host"])
  const userStore = useUserStore()

  const headers = {
    "Content-Type": "application/json",
    "X-Site": requestHeaders?.host,
  }

  if (userStore.token) {
    headers.Authorization = `Bearer ${userStore.token}`
  }

  return useFetch(url, {
    headers,
    ...options,
    $fetch: $api,
  })
}
