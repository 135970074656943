export const useGeoLocation = () => {
  const route = useRoute()

  // Create a state variable that is initialized with the request headers on the server.
  const geoHeaders = useState("geoHeaders", () => {
    // Only on the server will useRequestHeaders() be available
    return import.meta.server ? useRequestHeaders() : {}
  })

  const getGeoLocation = () => {
    if (route.query.geo) {
      // TODO: Don't allow query manipulation in production
      return route.query.geo.toUpperCase()
    } else {
      // Use the geo header values stored in our state
      const country = geoHeaders.value?.["cloudfront-viewer-country"] || "US"
      const region = geoHeaders.value?.["cloudfront-viewer-country-region"]
      return region ? `${country}-${region}` : country
    }
  }

  const ageRestrictedAreas = [
    "GB",
    "UK",
    "US-AL",
    "US-AR",
    "US-FL",
    "US-GA",
    "US-ID",
    "US-IN",
    "US-KS",
    "US-KY",
    "US-LA",
    "US-MS",
    "US-MT",
    "US-NC",
    "US-NE",
    "US-OK",
    "US-SC",
    "US-TN",
    "US-TX",
    "US-UT",
    "US-VA",
  ]

  return {
    country: getGeoLocation().split("-")[0],
    inAgeRestrictedLocation: ageRestrictedAreas.includes(getGeoLocation()),
    location: getGeoLocation(),
  }
}
