export default defineNuxtPlugin((nuxtApp: any) => {
  const headers = import.meta.server ? useRequestHeaders() : null
  const host = import.meta.server
    ? headers?.host // Server-side
    : window.location.host // Client-side
  const userStore = useUserStore()

  const api = $fetch.create({
    onRequest({ options }) {
      options.headers.set("Accept", "application/json")
      options.headers.set("Content-Type", "application/json")
      options.headers.set("X-Site", `${host}`)

      if (userStore.token) {
        options.headers.set("Authorization", `Bearer ${userStore.token}`)
      }
    },
    async onResponseError({ response }) {
      if (response.status === 401) {
        await nuxtApp.runWithContext(() => navigateTo("/login"))
      }
    },
  })

  return {
    provide: {
      api,
    },
  }
})
