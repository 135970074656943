import { defineStore } from "pinia"

export const useUserStore = defineStore(
  "user",
  () => {
    const id = ref(null)
    const username = ref(null)
    const email = ref(null)
    const siteAccessList = ref([])
    const token = ref(null)
    const status = ref(null)
    const expired = ref(false)
    const trial = ref(false)
    const active = ref(false)
    const accessLevel = ref(null)
    const permissions = ref([])
    const canStream = ref(false)
    const canDownload = ref(false)
    const biller = ref(null)
    const joinedAt = ref(null)
    const loggedIn = ref(false)
    const siteId = ref(null)
    const siteName = ref(null)
    const siteShortName = ref(null)
    const ageConfirmed = ref(false)
    const camUrls = ref({})
    const trailerPlayCount = ref(0)
    const billerJoinEpochDismissed = ref(false)
    const saleSpecialDismissedDate = ref(null)
    const isSaleSpecialDismissed = ref(true)
    const redirectUrl = ref(null)
    const dismissalDate = new Date(saleSpecialDismissedDate.value)
    const currentDate = new Date()
    const reactions = ref([])
    if (currentDate > dismissalDate) {
      clearSaleSpecialDismissal()
      isSaleSpecialDismissed.value = false
    } else {
      isSaleSpecialDismissed.value = true
    }

    const signedLinkToCamSite = computed(() => {
      if (camUrls.value?.streammate_urls?.root) {
        return camUrls.value.streammate_urls.root
      } else {
        return null
      }
    })

    function confirmAge() {
      ageConfirmed.value = true
    }

    async function login(provided_username: string, provided_password: string) {
      try {
        const headers = useRequestHeaders(["host"])
        const host = import.meta.server ? headers?.host : window.location.host
        const response = await fetch(`/api/session`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Site": host,
          },
          body: JSON.stringify({
            username: provided_username,
            password: provided_password,
          }),
        })

        if (response.ok) {
          const data = await response.json()
          id.value = data.id
          username.value = data.username
          email.value = data.email
          token.value = data.token
          status.value = data.status
          accessLevel.value = data.accessLevel
          trial.value = data.accessLevel === "trial"
          expired.value = data.accessLevel === "expired"
          active.value = data.accessLevel === "active"
          permissions.value = data.permissions
          canStream.value = data.canStream
          canDownload.value = data.canDownload
          biller.value = data.biller
          joinedAt.value = data.joinedAt
          siteId.value = data.site_id
          siteAccessList.value = data.siteAccessList
          siteName.value = data.siteName
          siteShortName.value = data.siteShortName
          ageConfirmed.value = true
          loggedIn.value = true
          reactions.value = data.reactions
          camUrls.value = data.camUrls
          trailerPlayCount.value = 0
          redirectUrl.value = data.redirectUrl

          const cookie = useCookie("i", {
            path: "/",
            maxAge: 60 * 60 * 24 * 7, // 7 days expiration
            // httpOnly: true, // Important for security
            secure: process.env.NODE_ENV === "production", // Only set 'Secure' in production
          })
          cookie.value = data.token

          return data
        }
      } catch (error) {
        console.error("Login failed:", error.message)
        this.$reset()
      }
    }

    async function loginWithToken(t: string) {
      try {
        const headers = useRequestHeaders(["host"])
        const host = import.meta.server ? headers?.host : window.location.host
        const response = await fetch(`/api/session/token`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Site": host,
          },
          body: JSON.stringify({
            token: t,
          }),
        })

        if (response.ok) {
          const data = await response.json()
          id.value = data.id
          username.value = data.username
          email.value = data.email
          token.value = data.token
          status.value = data.status
          accessLevel.value = data.accessLevel
          trial.value = data.accessLevel === "trial"
          expired.value = data.accessLevel === "expired"
          active.value = data.accessLevel === "active"
          permissions.value = data.permissions
          canStream.value = data.canStream
          canDownload.value = data.canDownload
          biller.value = data.biller
          joinedAt.value = data.joinedAt
          siteId.value = data.site_id
          siteAccessList.value = data.siteAccessList
          siteName.value = data.siteName
          siteShortName.value = data.siteShortName
          ageConfirmed.value = true
          loggedIn.value = true
          reactions.value = data.reactions

          camUrls.value = data.camUrls
          trailerPlayCount.value = 0
          redirectUrl.value = data.redirectUrl
          const cookie = useCookie("i", {
            path: "/",
            maxAge: 60 * 60 * 24 * 7, // 7 days expiration
            // httpOnly: true, // Important for security
            secure: process.env.NODE_ENV === "production", // Only set 'Secure' in production
          })
          cookie.value = data.token

          return data
        } else {
          return { error: "Invalid token" }
        }
      } catch (error) {
        console.error("Login failed:", error.message)
        this.$reset()
      }
    }

    async function storeUserFromGuestAccess(data: any) {
      id.value = data.id
      username.value = data.username
      email.value = data.email
      token.value = data.token
      status.value = data.status
      accessLevel.value = data.accessLevel
      trial.value = data.accessLevel === "trial"
      expired.value = data.accessLevel === "expired"
      active.value = data.accessLevel === "active"
      permissions.value = data.permissions
      canStream.value = data.canStream
      canDownload.value = data.canDownload
      biller.value = data.biller
      joinedAt.value = data.joinedAt
      siteId.value = data.site_id
      siteAccessList.value = data.siteAccessList
      siteName.value = data.siteName
      siteShortName.value = data.siteShortName
      ageConfirmed.value = true
      loggedIn.value = true
      reactions.value = data.reactions
      camUrls.value = data.camUrls
      trailerPlayCount.value = 0
      redirectUrl.value = data.redirectUrl

      return data
    }

    function incrementTrailerPlayCount() {
      trailerPlayCount.value++
    }

    function dismissBillerJoinEpoch() {
      billerJoinEpochDismissed.value = true
    }

    function dismissSaleSpecial(daysToExpire = 30) {
      const expiryDate = new Date()
      expiryDate.setDate(expiryDate.getDate() + daysToExpire)
      saleSpecialDismissedDate.value = expiryDate.toISOString()
      isSaleSpecialDismissed.value = true
    }

    function isAllowedToInteract() {
      return expired.value === false && trial.value === false
    }

    function clearSaleSpecialDismissal() {
      saleSpecialDismissedDate.value = null
      isSaleSpecialDismissed.value = false
    }

    function $reset() {
      accessLevel.value = null
      biller.value = null
      joinedAt.value = null
      loggedIn.value = false
      permissions.value = []
      token.value = null
      username.value = null
      email.value = null
      camUrls.value = {}
      ageConfirmed.value = false
      trailerPlayCount.value = 0
      redirectUrl.value = null

      const cookie = useCookie("i")
      cookie.value = null
    }

    return {
      $reset,
      accessLevel,
      active,
      ageConfirmed,
      biller,
      billerJoinEpochDismissed,
      canDownload,
      canStream,
      confirmAge,
      dismissBillerJoinEpoch,
      dismissSaleSpecial,
      expired,
      id,
      incrementTrailerPlayCount,
      isSaleSpecialDismissed,
      joinedAt,
      loggedIn,
      login,
      loginWithToken,
      permissions,
      siteAccessList,
      siteId,
      siteName,
      siteShortName,
      status,
      storeUserFromGuestAccess,
      token,
      trailerPlayCount,
      trial,
      username,
      email,
      camUrls,
      signedLinkToCamSite,
      redirectUrl,
      isAllowedToInteract,
      reactions,
    }
  },
  {
    persist: true,
  },
)
